/* General styles */
*{
  --colorverdeclaro : #606C38;
  --colorverdeoscuro : #283618;
  --colorblandohabano : #FEFAE0;
  --colornaranjaclaro : #DDA15E;
  --colornaranjaoscuro : #BC6C25;
  font-family: "Cinzel", Georgia, serif;
}


.dm-serif-text-regular {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}

.dm-serif-text-regular-italic {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: italic;
}

/*background-color: var(--main-bg-color);*/

body {
  margin: 0;
  font-family: "DM Serif Text", serif;
}

.App {
  text-align: center;
  color: #333;
}

h1, h2 {
  margin: 0.5em 0;
}

.nav-class {
  background-color: transparent; /* O el color que prefieras */
  padding: 1em 0;
  position: fixed; /* Hace que el nav quede fijo en la parte superior */
  top: 0;
  left: 0; /* Asegura que el nav esté alineado a la izquierda */
  width: 100%; /* Asegura que el nav ocupe todo el ancho de la pantalla */
  z-index: 1000; /* Asegura que el nav esté por encima de otros elementos */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); /* Sombra borrosa alrededor del nav */
  border-radius: 5px; /* Opcional, para esquinas redondeadas */
}


.App-header {
  margin-top: 0;
  padding: 0;
}

.banner {
  position: relative;
  overflow: hidden;
}



.banner-img {
  width: 100%;
  height: auto;
  animation: revealCurtain 2s ease-out forwards; /* Aplica la animación */
  clip-path: inset(0 50% 0 50%);
}

@keyframes revealCurtain {
  0% {
    clip-path: inset(0 50% 0 50%);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

/* Estilos por defecto para el nav */
.nav-class {
  background-color: transparent; /* Fondo transparente por defecto */
  padding: 1em 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: background-color 0.3s ease; /* Transición suave al cambiar el color */
}

/* Estilos cuando se hace scroll */
.nav-class.scrolled {
  background-color: rgba(4, 31, 4, 0.5); /* Color verde oscuro con 50% de opacidad */
}


/* Otros estilos de la navegación */
.lista-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: end;
}

.lista-nav li {
  margin: 0 15px;
}

.lista-nav a {
  color: var(--colorblandohabano);
  text-decoration: none;
  font-weight: bold;
}

.lista-nav a:hover {
  text-decoration: underline;
}

.nav-class {
  background-color: transparent;
  padding: 1em 0;
  position: fixed; /* Cambiado a 'fixed' para que permanezca fija en la parte superior */
  top: 0;
  width: 100%;
  z-index: 10; /* Asegura que el nav esté encima de otros elementos */
}


.App-header {
  margin-top: 0;
  padding-top: 0; /* Para que la imagen inicie justo desde la parte superior */
}



.lista-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: end;
}

.lista-nav li {
  margin: 0 15px;
}

.lista-nav a {
  color: var(--colorblandohabano);
  text-decoration:none;
  box-shadow: wheat;
  font-weight: bold;
}

.lista-nav a:hover {
  text-decoration: underline;
}

/* Banner styles */
.App-header {
  position: relative;
  width: 100%;
  height: auto;
}

.titulo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--colorblandohabano);
  font-size: 30px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Sombra del texto */
  z-index: 2;
}

.banner img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover; /* Ajusta la imagen sin distorsión */
  image-rendering: -webkit-optimize-contrast; /* Para navegadores WebKit */
  image-rendering: crisp-edges;
  filter: brightness(85%);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.9); /* Sombra de la imagen */
}

/*botones*/

.toggle-images-button{
  width: 100px;
  height: 40px;
  margin-top: 20px;
  border-radius: 30px;
  border: 1px solid var(--colorverdeclaro);
  background-color: var(--colorverdeoscuro);
  color: var(--colorblandohabano);
}

.toggle-cabins-button{
  width: 100px;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 30px;
  border: 1px solid var(--colorverdeclaro);
  background-color: var(--colorverdeoscuro);
  color: var(--colorblandohabano);
}
/* Image Gallery styles */
.image-gallery {
  padding: 2em 0;
  background-color: floralwhite;
}

.images {
  display: flex;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
}

.images img {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px; /* Esquinas redondeadas */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3); /* Sombra difusa de la imagen */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transiciones suaves para las animaciones */
}

.images img:hover {
  transform: scale(1.05); /* Aumenta ligeramente el tamaño de la imagen */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5); /* Sombra difusa más pronunciada al pasar el cursor */
}

.show-more-button {
  margin-top: 1em;
  padding: 0.5em 1em;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.show-more-button:hover {
  background-color: #004999;
}

/* Parrafo*/

.parrafo {
  display: flex;
  align-items: center;
  gap: 20px; /* Espacio entre la imagen y el texto */
  padding: 20px;
  background: linear-gradient(70deg, #1d481f, #efd844);
  box-sizing: border-box;
}

.imagen-contenedor {
  flex: 1;
  max-width: 100%; /* Asegura que el contenedor no sea más ancho que su contenedor padre */
  height: auto; /* La altura se ajustará automáticamente para mantener la proporción */
  padding: 25px;
  background-color: var(--colorblandohabano);
  box-sizing: border-box; /* Incluye el padding en el ancho total del contenedor */
  display: flex;
  align-items: center;
  justify-content: center; /* Centra la imagen dentro del contenedor */
}

.banner-img {
  max-width: 100%; /* La imagen se ajustará al tamaño del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  display: block; /* Elimina el espacio en blanco debajo de la imagen */
}



.parrafo p {
  flex: 2;
  margin: 0;
  text-align: center; /* Alinea el texto a la izquierda */
  font-size: 11px;
  color: white;
  line-height: 1.5;
}

/* Estilos para pantallas más grandes */
@media (min-width: 768px) {
  .parrafo p {
    font-size: 18px; /* Aumenta el tamaño del texto en pantallas grandes */
  }
}

/* Estilos para pantallas muy pequeñas */
@media (max-width: 480px) {
  .parrafo {
    flex-direction: column; /* Apila imagen y texto verticalmente en pantallas pequeñas */
    text-align: center;
  }
}


/* Estilos para pantallas más grandes */
@media (min-width: 768px) {
  .parrafo p {
    font-size: 23px; /* Aumenta el tamaño del texto en pantallas grandes */
    padding: 50px;
  }
}

/* Estilos para pantallas muy pequeñas */
@media (max-width: 480px) {
  .parrafo p {
    font-size: 16px; /* Disminuye el tamaño del texto en pantallas pequeñas */
    padding: 10px;
  }

  h1{
    font-size: 30px;
  }
}

@media (max-width: 360px) {
  .parrafo p {
    font-size: 16px; /* Disminuye el tamaño del texto en pantallas pequeñas */
    padding: 10px;
  }

  h1{
    font-size: 30px;
  }

  .lista-nav li{
    font-size: 13px;

  }
}



/* CabinCard styles */

.cabanas{
  
  background-color: floralwhite;
  border-top: 1px solid var(--colorverdeoscuro);
  margin-top: -15px;
}
.cabin-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
}

.cabin-card {
  background-color: var(--colornaranjaoscuro);
  border-radius: 15px;
  color: var(--colorblandohabano);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
  width: 100%;
  max-width: 300px;
  height: 400px;
  text-align: center;
  transition: transform 0.2s ease;
}

.cabin-card img {
  width: 100%;
  height: auto;
}

.cabin-card h3 {
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 1.5rem;
  color: var(--colorblandohabano  );
}

.cabin-card p {
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 15px;
  color: var(--colorblandohabano);
}

.more-info-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--colorverdeoscuro);
  color: #fff;
  text-decoration: none;
  margin-top: 25px;
  border-radius: 25px;
  border: 1px solid var(--colorblandohabano);
  transition: background-color 0.3s ease;
}

.more-info-button:hover {
  background-color: var(--colorverdeclaro);
}

@media (min-width: 600px) {
  .cabin-card {
    width: 45%;
  }
}

@media (min-width: 900px) {
  .cabin-card {
    width: 30%;
  }
}

.cabin-card:hover {
  transform: scale(1.05);
}

/* Map styles */
.map-section {
  padding: 2em 0;
  border-top: 1px solid var(--colorverdeoscuro);
  background-color: floralwhite;
}

.map-section iframe {
  border: 0;
  width: 100%;
  max-width: 600px;
  height: 450px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Footer styles */
.App-footer {
  background-color: var(--colorverdeoscuro);
  color: var(--colorblandohabano);
  padding: 2em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.App-footer div {
  margin: 0.5em 0;
}

.boton-res {
  margin-top: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.boton-res .reserve-button {
  background-color: #25D366;
  color: white;
  margin: 90px;
  padding: 15px 25px;
  text-decoration: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  width: 250px;
}

.boton-res .reserve-button img {
  width: 20px;
  height: 20px;
}

.boton-res .reserve-button:hover {
  background-color: #1ebc58;
}

/* Media Query para pantallas más grandes */
@media (min-width: 768px) {
  .App-footer {
    padding: 2em 4em;
    flex-direction: row;
    justify-content: center; /* Centra todo el contenido */
    align-items: center;
    text-align: center; /* Alinea el texto al centro */
  }

  .App-footer div {
    flex: 1;
    margin: 0;
    text-align: center; /* Asegura que cada div esté centrado */
  }

  .boton-res {
    margin-top: 0;
    justify-content: center; /* Centra el botón en el footer */
  }
}

.contact-info {
  margin-bottom: 2em;
  text-align: center; /* Alinea la información de contacto al centro */
}

.contact-info h2 {
  margin-bottom: 1em;
  font-size: 1.5em;
  color: var(--colorblandohabano);
  text-align: center;
}

.contact-info p {
  margin: 0.5em 0;
  font-size: 1.1em;
  text-align: center;
}

.social-icons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 1em;
  align-items: center; /* Asegura que los íconos estén centrados */
}

.social-icons a {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: var(--colorblandohabano);
  justify-content: center;
}

.social-icons img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.social-icons p {
  margin: 0;
  font-size: 1.1em;
}
